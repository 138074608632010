<template lang="">
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="p-0 filter-card rounded-lg w-100">
                    <b-col cols="12">
                        <h3 class="ml-1 mb-2">Filters</h3>
                    </b-col>
                    <b-col 
                        cols="4" 
                        v-if="hasPermission('Trashed Materials')"
                    >
                        <v-select
                            v-model="filter.trashed"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="trashOptions"
                            class="w-100 mb-0"
                            :reduce="(val) => val.id"
                            placeholder="Status"
                            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                        >
                            <template v-slot:option="option">
                            {{ $i18n.locale == "ar" ? option.name_local : option.name }}
                            </template>
                            <template #selected-option="{ name, name_local }">
                            <div style="display: flex; align-items: baseline">
                                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                                <strong v-else>{{ name }} </strong>
                            </div>
                            </template>
                            <template #no-options>
                            {{ $t("noMatching") }}
                            </template>
                        </v-select>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="w-100 d-flex rounded-lg">
            <b-row>
                <b-col md="12" class="pb-2 w-100 d-flex justify-content-between">
                    <h3 class="ml-1 mb-2">Courses</h3>
                    <div>
                        <!-- <b-button
                            class="btn rounded-pill ml-2"
                            variant="primary"
                            @click="loadData()"
                        >
                            {{filter.trashed == 1 ? 'List' : 'Trashed'}}
                        </b-button> -->
                        <b-button
                            class="btn rounded-pill"
                            variant="primary"
                            @click="addCourse()"
                        >
                            Create Course
                        </b-button>
                        
                    </div>
                </b-col>
            </b-row>
            <center v-if="load">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </center>
            <b-table 
                :items="items.data" 
                :fields="fields" 
                striped 
                responsive
                v-else
            >
            
                <template #cell(Actions)="data">
                    <div class="d-flex justify-content-start align-items-center" v-if="filter.trashed == 1 || filter.trashed == null">
                        <b-link
                            :tooltip="'none'"
                            class="alert-link cutom-alert-link text-danger mr-1"
                            @click="restoreCourse(data.item.id)"
                        >
                            <feather-icon :tooltip="'none'" icon="RotateCcwIcon" size="18" v-b-tooltip.hover.top="'restore'"/>
                        </b-link>
                        <b-link
                            :tooltip="'none'"
                            class="alert-link cutom-alert-link text-danger"
                            @click="hardDeleteCourse(data.item.id)"
                        >
                            <feather-icon :tooltip="'none'" icon="Trash2Icon" size="18" v-b-tooltip.hover.top="'Delete'"/>
                        </b-link>
                    </div>
                    <div class="d-flex justify-content-start align-items-center" v-else>
                        <b-link
                            class="alert-link cutom-alert-link"
                            :to="{ name: 'modules', query: { course_id: data.item.id} }"
                        >
                            <feather-icon icon="EyeIcon" size="18" v-b-tooltip.hover.top="'Show'"/>
                        </b-link>
                        <b-link
                            :tooltip="'none'"
                            class="alert-link cutom-alert-link mx-1"
                            :to="{ name: 'edit_course', params: { id: data.item.id} }"
                        >
                            <feather-icon :tooltip="'none'" icon="EditIcon" size="18" v-b-tooltip.hover.top="'Edit'"/>
                        </b-link>
                        <b-link
                            :tooltip="'none'"
                            class="alert-link cutom-alert-link text-danger"
                            @click="deleteCourse(data.item.id)"
                        >
                            <feather-icon :tooltip="'none'" icon="Trash2Icon" size="18" v-b-tooltip.hover.top="'Delete'"/>
                        </b-link>
                    </div>
                </template>
            </b-table>
            <b-row>
                <b-col md="12" class="d-flex justify-content-center">
                    <b-pagination
                        v-model="items.current_page"
                        :total-rows="items.total"
                        :per-page="items.per_page"
                        @change="onPageChange"
                    ></b-pagination>
                </b-col>
            </b-row>

        </b-card>
    </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
export default {
    name:'courses',
    components: {
        BTable,
        BButton,
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BBadge,
        BAvatar,
        BCardText,
        BLink,
        ToastificationContent,
        vSelect,
        BPagination,
        BSpinner,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    watch:{
        "filter.trashed"(newVal) {
            this.$store.dispatch("courses/list", this.filter);
        },
    },
    computed: {
        ...mapGetters({
            items: "courses/items",
            load: "courses/load",
        }),
        id() {
            return this.$route.query.id
                ? this.$route.query.id 
                : null;
        },
    },
    data() {
        return {
            filter: {
                trashed: 0,
            },
            trashOptions:[
                {id: 0 , name: 'Active'},
                {id: 1 , name: 'Inactive'},
            ],
            fields: [
                { key: "name", label: "Name" },
                { key: "description", label: "Description" },
                "Actions",
            ],
        }
    },
    methods:{
        init() {
            this.$store.dispatch("courses/list", { ...this.filter }).then(_=>{
            })
        },
        onPageChange(page) {
            this.$store
            .dispatch("courses/list", { ...this.filter, page:page })
        },
        addCourse(){
            this.$router.push({name:'add_course'})
        },
        deleteCourse(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to delete!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel it!',
                confirmButtonColor: '#E84185',
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch("courses/remove", id).then(_=>{
                        this.init(); 
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            confirmButtonColor: '#E84185',
                        })
                    }).catch(error => {
                        if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.error}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                        }
                    });
                }
            })
        },
        hardDeleteCourse(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to hard delete!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel it!',
                confirmButtonColor: '#E84185',
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch("courses/hardRemove", id).then(_=>{
                        this.init(); 
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            confirmButtonColor: '#E84185',
                        })
                    }).catch(error => {
                        if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.error}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                        }
                    });
                }
            })
        },
        restoreCourse(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to restore!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, restore it!',
                cancelButtonText: 'No, cancel it!',
                confirmButtonColor: '#E84185',
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch("courses/restore", id).then(_=>{
                        this.init(); 
                        this.$swal({
                            icon: 'success',
                            title: 'Restored!',
                            confirmButtonColor: '#E84185',
                        })
                    }).catch(error => {
                        if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.error}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                        }
                    });
                }
            })
        }
    },
    mounted() {
        this.init();
    },
}
</script>
<style lang="scss">
    
</style>